import React, { useEffect, useState } from 'react';
import './Deems.scss';
import thumb from './images/deems-thumb.png';

const Deems = () => {
	const [hover, setHover] = useState(false);
	const [rx, setRX] = useState(0);
	const [ry, setRY] = useState(0);

	const onmousemove = (evt: MouseEvent | TouchEvent) => {
		const vw = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
		const vh = window.innerHeight|| document.documentElement.clientHeight|| document.body.clientHeight;
		const x = (evt as MouseEvent).clientX || (evt as TouchEvent).touches[0]?.clientX || 0;
		const y = (evt as MouseEvent).clientY || (evt as TouchEvent).touches[0]?.clientY || 0;
		const nx = (x - (vw / 2)) / (vw / 2);
		const ny = (y - (vh / 2)) / (vh / 2);
		setRX(-ny * 20);
		setRY(nx * 20);

		evt.preventDefault();
		return false;
	};

	useEffect(() => {
		window.addEventListener('mousemove', onmousemove);
		window.addEventListener('touchmove', onmousemove);
		return () => {
			window.removeEventListener('mousemove', onmousemove);
			window.removeEventListener('touchmove', onmousemove);
		}
	});

	let deemsClassName = 'deems';
	if (hover) deemsClassName += ' active';

	return (
		<div className={deemsClassName}>
		<div className="thumb-accent" style={{ transform: `rotateX(${rx}deg) rotateY(${ry}deg)` }}></div>
		<img className="deems-thumb" src={thumb} alt="deems" style={{ transform: `rotateX(${rx}deg) rotateY(${ry}deg) translateZ(10px)` }} />
		<div className="link-container" style={{ transform: `rotateX(${rx}deg) rotateY(${ry}deg) translateZ(50px)` }}>
			<a href="mailto:jerome@ndfnz.io" className="link" onMouseOver={() => setHover(true)} onMouseOut={() => setHover(false)}>jerome@ndfnz.io</a>
		</div>
		</div>
	);
}

export default Deems;
